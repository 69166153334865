import React from 'react'
import AppBar from './components/appBar'
import Articles from './routes/articles'

function App() {
  return (
    <div className="App">
      <AppBar />
      <Articles />
    </div>
  );
}

export default App;
