import { useState } from 'react'
import { Card, CardHeader, CardMedia, CardContent, CardActions, Button } from '@mui/material'
import Text from './text'

function App({title, date, content, image}) {

  const [expanded, setExpanded] = useState(false)

  const toggle = () => setExpanded(!expanded)
    
  return (
    <Card>
        <CardHeader
        title={title}
        subheader={date}
        />
        <CardMedia
        component="img"
        image={image}
        alt="img"
        height="250"
        />
        <CardContent sx={{maxHeight: expanded ? '' : 250, textAlign: 'justify', fontFamily: "Roboto, Helvetica Arial ,sans-serif"}}>
            {
                content.map(c => <Text content={c}/>)
            }
        </CardContent>
        <CardActions sx={{backfaceVisibility: "hidden", background: "white", opacity: 1, justifyContent: 'flex-end'}}>
        <Button onClick={toggle}>{expanded ? 'Collapse' : 'Read More'}</Button>
        </CardActions>
    </Card>
  );
}

export default App;
