import { React } from 'react'

export default function Text({content=''}) {
    console.log(content)
    let bold = content.split('')[0] == '*'
    return (
        <div>
            {bold ? <b>{content}</b> : <>{content}<br/><br/></>}
        </div>
    );
} 
