import { Container } from '@mui/material';
import content from '../content'
import { useState } from 'react'
import ArticleCard from '../components/articleCard'

function App() {

  const [data, setData] = useState([
    {
      title: "ಸ್ವಾತಂತ್ರ್ಯ ಸಂಗ್ರಾಮಕ್ಕೆ ಪತ್ರಿಕೆಗಳ ಕಾಣ್ಕೆ",
      date: "15/August/2022",
      content: content,
      image: "assets/images/freedom.jpeg"
    }
  ])

  return (
    <Container sx={{pt: 8}}>
        {data.map(a => <ArticleCard title={a.title} date={a.date} content={a.content} image={a.image}/>)}
    </Container>
  );
}

export default App;
